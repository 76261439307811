$primary: #c03c2b; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

 @import url('https://fonts.googleapis.com/css?family=Barrio');

.flash {
	display:none;
}

body {
	font-size: 23px;
}

.btn {
	font-size: 0.75em;
	letter-spacing: 1px;
}

h1,h2,h3,h4 {
	font-family: 'Barrio',arial,sans-serif;
}

h2 {
	font-size: 1.5em;
	color: $primary;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 118px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 40px;
		    font-size: 16px;
		    display: inline-block;
		}

	    &:hover {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    	outline: 0 !important;
	    }

	    &:focus {
	    	background: transparent;
	    	color: #777;
	    	outline: 0 !important;	
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}

.navbar-default {
	border-radius: 0px;
	margin-top: -2px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}





















/**************** FEATURES *************************/
.features-boxed {
  color:black;
  background-color:$primary;
  z-index:2;
}

.features-boxed p {
  color:#7d8285;
}

.features-boxed h2 {
  margin-bottom:40px;
  padding-top:40px;
  color:white;
}

@media (max-width:767px) {
  .features-boxed h2 {
    margin-bottom:25px;
    padding-top:25px;
  }
}

.features-boxed .intro {
  margin:0 auto;
}

.features-boxed .intro p {
  margin-bottom:0;
  color:white;
}

.features-boxed .features {
  padding:50px 0;
}

.features-boxed .item {
  text-align:center;
}

.features-boxed .item .box {
  text-align:center;
  padding:30px;
  background-color:#fff;
  margin-bottom:30px;
}

.features-boxed .item .icon {
  color:$primary;
  margin-top:20px;
  margin-bottom:35px;
  font-size: 60px;
}

.features-boxed .item .name {
  margin-bottom:8px;
  margin-top:0;
  color:$primary;
}

.features-boxed .item .description {
  margin-top:15px;
  margin-bottom:20px;
}




/******************** HIGHLIGHT PHONE **************************/
.highlight-phone {
  color:#313437;
  background-color:#eef4f7;
  padding:50px 0 55px;
  overflow:hidden;
}

@media (max-width:767px) {
  .highlight-phone {
    text-align:center;
  }
}

.highlight-phone p {
  color:#7d8285;
}

.highlight-phone h2 {
  margin-bottom:35px;
  line-height:1.5;
  padding-top:0;
  margin-top:0;
  color:$primary;
}

.highlight-phone .intro {
  max-width:500px;
  line-height:1.5;
}

@media (max-width:767px) {
  .highlight-phone .intro {
    max-width:none;
  }
}

.highlight-phone .intro p {
  margin-bottom:35px;
}

 .btn {
  padding:16px 32px;
  border:none;
  background:none;
  box-shadow:none;
  text-shadow:none;
  opacity:0.9;
  text-transform:uppercase;
  letter-spacing:0.4px;
  line-height:1;
}

 .btn:hover {
  opacity:1;
}

.btn:active {
  transform:translateY(1px);
}

.btn-primary {
  background-color:$primary;
  color:#fff;
}

.highlight-phone .iphone-mockup {
  position:relative;
  max-width:260px;
  margin-top:-30px;
  margin-bottom:-230px;
  transform:rotate(-15deg);
	@media (max-width: 767px){
	  margin: 4em auto 0em auto;
	  max-width: 200px;
	}
}

.highlight-phone .iphone-mockup img.device {
  width:100%;
  height:auto;
}

.highlight-phone .iphone-mockup .screen {
  position:absolute;
  width:88%;
  height:77%;
  top:12%;
  border-radius:2px;
  left:6%;
  border:1px solid #444;
  background-color:#aaa;
  overflow:hidden;
  background:url(../img/nerdCouple.png);
  background-size:cover;
  background-position:center;
}

.highlight-phone .iphone-mockup .screen:before {
  content:'';
  background-color:#fff;
  position:absolute;
  width:70%;
  height:140%;
  top:-12%;
  right:-60%;
  transform:rotate(-19deg);
  opacity:0.2;
}






/*********** MY STYLES **************/

#heroImg1 {
	background: url(../img/hero1.jpg) no-repeat center;
}

#heroImg2 {
	background: url(../img/hero2.jpg) no-repeat center;
}

#heroImg3 {
	background: url(../img/hero3.jpg) no-repeat center;
}

#heroImg1, #heroImg2, #heroImg3 {
	background-size: cover;
	padding: 12em 0em;
	@media (max-width: 767px) {
		padding: 8em 0em;
	}
}


.noPad {
  padding-left:0;
  padding-right:0;
}

.skew {
  transform:skewX(-10deg);
}

.skewPad {
	padding: 8em 0em;
	@media (max-width: 767px) {
		padding: 2em;
	}
	// margin:0em 3em 0em -4em;
}



#imgSection h2 {
	  font-size: 2.5em;
  	@media (max-width: 767px) {
		font-size: 1.7em;
	}
}

#imgSkew {
  background:url(../img/gaming.png) no-repeat center;
  background-size:cover;
  overflow:hidden;
  transform:skewX(-10deg);
  	padding: 17em 0em;
	margin:0em 3em 0em -4em;  
		@media (max-width: 1350px) {
			padding: 19em 0em;
		}  	

	h2 {
	  color:white;
		  @media (max-width: 1350px) {
				font-size: 2em;
		  }  
	}	
}

.unSkew {
  transform:skewX(10deg);
}


.marBtm {
	margin-bottom: 2em;
}


.logo {
	max-width: 250px;
	margin: 0.5em;
	@media(max-width: 767px) {
		max-width: 150px;
	}
}





@media(max-width: 767px) {
  .navbar-header a {
    float: left;
  } 
}



.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}

@media (max-width: 991px) {
    footer {
    padding-left: 1em;
    padding-right: 1em;
  }

footer p {
    margin-bottom: 0.75em !important;
    font-size: 0.75em !important;
  }
}